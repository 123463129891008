import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="सौ. माधवी आशिर्वाद" />
        <HeaderGeneric title="इतर साहित्य" adhyaay='सौ. माधवी संजीव वैजनापुरकर (माधवी चंद्रकात भोकरकर) आशिर्वाद'/>
        <div id="main">
          <section id="content" className="main">
            <p>
॥ श्री गजानन महाराज प्रसन्न ॥< br />
॥ श्री आठवले गुरू प्रसन्न ॥< br />
< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ धृ ॥< br />
< br />
प्रातःस्मरणी समुद्रवसने< br />
आरंभावा दिन कराग्रेवसने< br />
प्रसन्न भावे प्रसन्न मनाने< br />
स्वागत प्रसन्न चित्ताने< br />
उषःकालचे स्वागत करणे< br />
चहा / कोफी मनोभावाने< br />
रात्रीचा आनंद स्मरणे< br />
निशदिनी पतिदेव पूजणे< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ १ ॥< br />
< br />
सासू सासरे तात-जननी< br />
शब्द झेलावे क्षणोक्षणी< br />
सदैव आज्ञा पालन करुनी< br />
घे तू मर्जी संपादुनी< br />
आशिर्वचने उघळुनी< br />
संतुष्ट होतील जीवनी< br />
ती सुमने एक एक वेचुनी< br />
जावे जीवनी उद्धरुनी< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ २ ॥< br />
< br />
दीर नणंदा - बंधु भगिनी< br />
ऐसा भाव राहु दे मनी< br />
चुलत्यांस सख्खे मानुनी< br />
नको भेदभाव कधी मनी< br />
एकेकाची कळी खुलवुनी< br />
मायेचा वृक्ष बहरो सदनी< br />
प्रेमभाव, सात्विक वर्तनी< br />
प्रकटो जे जे अंतःकरणी< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ३ ॥< br />
< br />
तुझे घर माहित नव्हते< br />
लिहिले होते जे ललाटे< br />
माहित होता ओढ लागते< br />
सुक्षणाची वाट बघते< br />
माहेरचे जे जे होते< br />
सासरात मिसळणार ते< br />
माहेर सासर नको भेद ते< br />
राहु दे केवळ नावापुरते< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ४ ॥< br />
< br />
काय शिकवण मातेची< br />
सुसंस्कृत शालीनतेची< br />
प्रेमभावे आतिथ्याची< br />
नाती गोती जिव्हाळ्याची< br />
हंसत मुखे स्वागताची< br />
कसब कुशलता मातेची< br />
संवय तुला अनुभवाची< br />
मातेच्या मार्गदर्शनाची< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ५ ॥< br />
< br />
स्वयंपाक करावा रुचकर< br />
भोजन वेळच्या वेळेवर< br />
हंसरे भाव चेहेर्‍यावर< br />
तृप्तीची यावी ढेकर< br />
असावा चौरस आहार< br />
आरोग्यास तो हितकारक< br />
आग्रह करून वाढल्यावर< br />
जेवणारा तृप्तच होणार< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ६ ॥< br />
< br />
पिता तुझे सुसंस्कारी< br />
सात्विकावर जोर भारी< br />
रघुनंदन सदा स्मरी< br />
रघुवीर समर्थ प्रेमे भारी< br />
श्रीधर स्वामी अंतरी< br />
अंतरीचे बोल उच्चारी< br />
सज्जनगडाची ओढ भारी< br />
सज्जनगडच माहेरी< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ७ ॥< br />
< br />
काय शिकवण पित्याची< br />
समर्थ दासबोधाची< br />
जीवन जगुन अलीप्त रहायची< br />
शिकवण उच्चकोटीची< br />
संसार करून परमार्थाची< br />
ओढ असावी चित्ताची< br />
समर्पण भावात जगण्याची< br />
परी लाचारी न पत्करायची< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ८ ॥< br />
< br />
बंधु तुझा मुकंदा ऐसा< br />
गणेश कृपेचाच ठसा< br />
सिद्धिविनायका विश्वासा< br />
म्हणे त्यावर ठेवा भरवंसा< br />
सुखकर्ता माझा गणेशा< br />
विघ्नहर्ता माझा गणेशा< br />
प्रारब्ध भोग सुसह्य कसा< br />
तुझ्या बंधुस अनुभव जैसा< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ९ ॥< br />
< br />
चातुर्मास सणवार< br />
धार्मिकतेवर ठेव भार< br />
धर्माचरणे असल्यावर< br />
त्याची मर्जी आपल्यावर< br />
नको भार स्तोमावर< br />
नको तो अवडंबरावर< br />
अंतरीच्या शुद्धतेवर< br />
त्रिमूर्ती संतोषणार< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ १० ॥< br />
< br />
एकेक पाऊल उचलताना< br />
नको विसरू शालीनपणा< br />
क्षणिक उच्छृंखलपणा< br />
कठीण तोल सावरण्या< br />
नको जीवनी क्लीष्टपणा< br />
जीव जाळणे क्षणाक्षणा< br />
जीवन वृक्ष जोपासण्या< br />
मायेचा ओलावा क्षणाक्षणा< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ ११ ॥< br />
< br />
विविध पदार्थ भोजनात< br />
अत्यावश्यक आहारात< br />
सर्वच सुरस होण्यात< br />
जिंकशील तू संसारात< br />
कालमान पाहुन स्वयंपाकात< br />
फेरफार करावे त्यात< br />
पाकशास्त्री निपुण होण्यात< br />
अन्नपूर्णा हवी घरात< br />
माधवी तू माहेरवाशीण< br />
होणार सासुरवाशीण ॥ १२ ॥< br />
< br />
- श्रीकांत य. फडके< br />
- सोमवार १४ मार्च १९९४< br />
            < br />
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
